/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Download from "pages/Presentation/sections/Download";


// import ReactCardCarousel from "react-card-carousel";

import Carousel from 'react-bootstrap/Carousel';

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/gica.jpg";
import minefop from "assets/images/minefop.jpg";
import front from "assets/images/gicam21.jpg";
import front2 from "assets/images/front2.jpeg";
// import bImage from "assets/images/back-minefop.jpg";
// import { Box } from "@mui/material";
import MKButton from "components/MKButton";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKAvatar from "components/MKAvatar";

import profilePicture from "assets/images/tchiroma.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import lawsData from "./sections/data/lawsData";
import newsData from "./sections/data/newsData";
import { useNavigate } from "react-router-dom";
import LawCards from "examples/Cards/LawCards";

function Presentation() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky={true}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        transparent={true}
        light={true}
      />
      <MKBox
        height="75vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          position: "relative",  // Ajouter position relative pour le conteneur principal
        }}
      >
        {/* <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "75vh",
            backgroundColor: "rgba(8, 56, 4, 0.6)",
            zIndex: 1,
          }}
        /> */}
        {/* <Container
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",  // Centrer verticalement et horizontalement
            zIndex: 2,
            textAlign: "center",  // Centrer le contenu textuel
          }}
        >
          <Grid container justifyContent="center" textAlign="center">
            <Grid item xs={12}>
              <MKTypography variant="h2" color="white">
                {t("Home.title")}
              </MKTypography>
            </Grid>
            <Grid item xs={12}>
              <Hidden smDown>
                <MKTypography
                  variant="body2"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                  mt={1}
                >
                  {t("Home.soustitle")}
                </MKTypography>
              </Hidden>
            </Grid>
          </Grid>
        </Container> */}
        <Carousel style={{ zIndex: 0 }} data-bs-theme="dark" controls={true}>
          <Carousel.Item>
            <>
              <img
                className="d-block w-100"
                style={{ height: '85vh', objectFit: 'fill' }}
                src={front}
                alt="First slide"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "85vh",
                  backgroundColor: "rgba(8, 56, 4, 0.6)",
                  zIndex: 1,
                }}
              />
            </>
            <Carousel.Caption
              style={{
                color: "#fff",
                top: "50%",
                left: "50%",
                width: "80%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                zIndex: 2,  // Ajoutez z-index ici
                position: "absolute",  // Ajoutez la position absolue
              }}
            >
              <MKTypography variant="h2" color="white">
                {t("Home.1.title")}
              </MKTypography>
              {/* <Hidden smDown>
                <MKTypography variant="body2" color="white">
                  {t("Home.soustitle")}
                </MKTypography>
              </Hidden> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <>
              <img
                className="d-block w-100"
                style={{ height: '85vh', objectFit: 'cover' }}
                src={bgImage}
                alt="Second slide"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "85vh",
                  backgroundColor: "rgba(8, 56, 4, 0.6)",
                  zIndex: 1,
                }}
              />
            </>
            <Carousel.Caption
              style={{
                color: "#fff",
                top: "50%",
                left: "50%",
                width: "80%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                zIndex: 2,  // Ajoutez z-index ici
                position: "absolute",  // Ajoutez la position absolue
              }}
            >
              <MKTypography variant="h2" color="white">
                {t("Home.2.title")}
              </MKTypography>
              {/* <Hidden smDown>
                <MKTypography variant="body2" color="white">
                  {t("Home.soustitle")}
                </MKTypography>
              </Hidden> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <>
              <img
                className="d-block w-100"
                src={front2}
                style={{ height: '85vh', objectFit: 'cover' }}
                alt="Third slide"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "85vh",
                  backgroundColor: "rgba(8, 56, 4, 0.6)",
                  zIndex: 1,
                }}
              />
            </>
            <Carousel.Caption
              style={{
                color: "#fff",
                top: "50%",
                left: "50%",
                width: "80%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                zIndex: 2,  // Ajoutez z-index ici
                position: "absolute",  // Ajoutez la position absolue
              }}
            >
              <MKTypography variant="h2" color="white">
                {t("Home.3.title")}
              </MKTypography>
              {/* <Hidden smDown>
                <MKTypography variant="body2" color="white">
                  {t("Home.soustitle")}
                </MKTypography>
              </Hidden> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </MKBox >

      {/* <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(8, 56, 4, 0.7)", 
            zIndex: 1,
          }}
        />
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Grid container item xs={12} lg={12} justifyContent="left" mx="auto">
            <MKTypography
              variant="h2"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Bienvenue dans le Fichier National des Travailleurs de Nationalité Etrangère au Cameroun
            </MKTypography>
            <MKTypography
              variant="body2"
              color="white"
              textAlign="left"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mt={1}
            >
              Ce registre centralisé vous permet de consulter et de gérer les informations relatives aux travailleurs de nationalité étrangère au Cameroun.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox> */}
      < Grid
        sx={{
          mt: -8,
          mb: 4
        }
        }
      >
        <Grid
          sx={{
            display: "flex",
            position: "relative",
            flexDirection: { xs: "column", md: "row" },  // Colonnes sur les petits écrans, lignes sur les grands écrans
            alignItems: "center",  // Alignement vertical
            gap: { xs: 15, md: 10 },  // Espace entre les cartes : 2 sur les petits écrans, 0 sur les grands écrans
            justifyContent: "space-between",
            mt: 15,
            zIndex: 1,
            marginBottom: { xs: 2, md: 0 }
          }}
        >
          <Card sx={{
            position: "relative",
            mr: { xs: 0, md: 5 },
            mt: -10,
            alignSelf: "center",
            flex: 1,
            mx: { xs: 0, md: 2 },
            cursor: "pointer", // Ajoute le curseur de pointeur
            "&:hover": {
              cursor: "pointer", // Change le curseur lors du survol
              boxShadow: 3, // Ajoute une ombre lors du survol pour un effet visuel
            },
          }} onClick={() => {
            navigate("/about")
          }}>
            <MKBox position="relative" alignSelf="center" borderRadius="lg" mx={2} mt={-3}>
              <MKAvatar style={{ backgroundColor: "#C28F0B" }} src={minefop} size="xl" shadow="xl">
              </MKAvatar>
            </MKBox>
            <MKBox p={3} mt={-1} textAlign="center">
              <MKTypography display="inline" variant="h4" textTransform="capitalize" fontWeight="bold">
                {t("Services.1")}
              </MKTypography>
            </MKBox>
          </Card>
          <Card sx={{
            position: "relative",
            mr: { xs: 0, md: 5 },
            mt: -10,
            alignSelf: "center",
            flex: 1,
            mx: { xs: 0, md: 2 },
            cursor: "pointer", // Ajoute le curseur de pointeur
            "&:hover": {
              cursor: "pointer", // Change le curseur lors du survol
              boxShadow: 3, // Ajoute une ombre lors du survol pour un effet visuel
            },
          }} onClick={() => {
            navigate("/fichier-national-travailleurs-etrangers-cameroun")
          }}>
            <MKBox position="relative" alignSelf="center" borderRadius="lg" mx={2} mt={-3}>
              <MKAvatar style={{ backgroundColor: "#C28F0B" }} src={minefop} size="xl" shadow="xl">
              </MKAvatar>
            </MKBox>
            <MKBox p={3} mt={-1} textAlign="center">
              <MKTypography display="inline" variant="h4" textTransform="capitalize" fontWeight="bold">
                {t("Services.2")}
              </MKTypography>
            </MKBox>
          </Card>
          <Card sx={{
            position: "relative",
            mr: { xs: 0, md: 5 },
            mt: -10,
            alignSelf: "center",
            flex: 1,
            mx: { xs: 0, md: 2 },
            cursor: "pointer", // Ajoute le curseur de pointeur
            "&:hover": {
              cursor: "pointer", // Change le curseur lors du survol
              boxShadow: 3, // Ajoute une ombre lors du survol pour un effet visuel
            },
          }} onClick={() => {
            navigate("/laws")
          }}>
            <MKBox position="relative" alignSelf="center" borderRadius="lg" mx={2} mt={-3}>
              <MKAvatar style={{ backgroundColor: "#C28F0B" }} src={minefop} size="xl" shadow="xl">
              </MKAvatar>
            </MKBox>
            <MKBox p={3} mt={-1} textAlign="center">
              <MKTypography display="inline" variant="h4" textTransform="capitalize" fontWeight="bold">
                {t("Services.3")}
              </MKTypography>
            </MKBox>
          </Card>
        </Grid>
        <Download />

        <Container>
          <Grid bgcolor={"#095228"} mb={5} lg={12} justifyContent={"center"} container>
            <Grid item lg={2}>
              <MKBox
                component="img"
                src={profilePicture}
                alt={"ISSA TCHIROMA BAKARY"}
                width="130%"
              />
              {/* <MKAvatar src={profilePicture} alt="ISSA TCHIROMA BAKARY" size="xxl" shadow="xl" /> */}
            </Grid>
            <Grid item xs={12} lg={10}>
              <MKBox ml={8} pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
                <MKTypography variant="h3" color="white">{t("Ministre.name")}</MKTypography>
                <MKTypography variant="body2" color={"white"} mb={2}>
                  {t("Ministre.poste")}
                </MKTypography>
                <Grid container xs={11}>
                  <Grid item lg={0}>
                    <MKTypography variant="h1" color="white">
                      {'"'}
                    </MKTypography>
                  </Grid>
                  <Grid item lg={11} mt={2}>
                    <MKTypography variant="body2" color="white" sx={{ textAlign: 'justify' }}>
                      {t("Ministre.message")}
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ position: "relative" }}>
          <Grid container item xs={7} md={7} justifyContent="left" textAlign="left">
            <MKTypography variant="h3" color="black" sx={{
              position: 'relative',
              display: 'inline-block',
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '50%',
                height: '3px',
                backgroundColor: '#FCE410',
                transform: 'translateX(-200%)',
              },
            }} mb={3} mr={5}>
              {t("Lois.title")}
            </MKTypography>
            <MKButton
              variant="contained"
              color="primary"
              size="large"
              component="a"
              href="/laws"
              sx={{ mb: 2 }}
            >
              {t("Lois.bouton")}
            </MKButton>
          </Grid>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: "column", md: "row" },
            flexWrap: 'wrap',
            gap: 2, // Espace entre les cartes
            marginBottom: 4,
          }}>
            {
              lawsData.slice(0, 3).map((item) => (
                <Box key={item?.id} sx={{
                  flex: '1 1 30%', // Définit la largeur de chaque carte (environ un tiers de la ligne)
                  maxWidth: '30%', // S'assure que les cartes ne dépassent pas 30% de la ligne
                  minWidth: '300px', // Définit une largeur minimale pour chaque carte
                }}>
                  <LawCards
                    key={item?.id}
                    image={item?.image}
                    category={{ color: "warning", label: "hub" }}
                    title={item?.title[i18n.language]}
                    data={item}
                    // description="Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons."
                    action={{
                      type: "internal",
                      route: `/details-laws/${item?.id}`,
                      label: t("Presentation.bouton")
                    }}
                    date={item?.date}
                  />
                </Box>
              ))
            }
          </Box>
        </Container>
        <Counters />
        <Container sx={{ position: "relative", mt: 6 }}>
          <Grid container item xs={12} md={7} justifyContent="left" textAlign="left">
            <MKTypography variant="h3" color="black" sx={{
              position: 'relative',
              display: 'inline-block',
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '50%',
                height: '3px',
                backgroundColor: '#FCE410',
                transform: 'translateX(-200%)',
              },
            }} mb={3} mr={5}>
              {t("Actualite.title")}
            </MKTypography>
            <MKButton
              variant="contained"
              color="primary"
              size="large"
              component="a"
              href="/news"
              sx={{ mb: 2 }}
            >
              {t("Actualite.bouton")}
            </MKButton>
          </Grid>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: "column", md: "row" },
            gap: 2, // Espace entre les cartes
            marginBottom: 4,
          }}>
            {
              newsData.slice(0, 3).map((item) => (
                <Box key={item?.id} sx={{
                  flex: '1 1 30%', // Définit la largeur de chaque carte (environ un tiers de la ligne)
                  maxWidth: '30%', // S'assure que les cartes ne dépassent pas 30% de la ligne
                  minWidth: '300px', // Définit une largeur minimale pour chaque carte
                }}>
                  <CenteredBlogCard
                    key={item?.id}
                    image={item?.image}
                    category={{ color: "warning", label: "hub" }}
                    title={item?.title[i18n.language]}
                    data={item}
                    // description="Use border utilities to quickly style the border and border-radius of an element. Great for images, buttons."
                    action={{
                      type: "internal",
                      route: `/details-news/${item?.id}`,
                      label: t("Presentation.bouton")
                    }}
                    date={item?.date}
                  />
                </Box>
              ))
            }
          </Box>
        </Container>
      </Grid >
      <MKBox>
        <DefaultFooter />
      </MKBox>
    </>
  );
}

export default Presentation;
